<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-1 "
          :loading-text="$t('table.messages.loading')"
          :loading="loading"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 30, 50],
            showFirstLastPage: false,
            'items-per-page-text': $t('table.messages.items_per_page'),
          }"
        >
          <template slot="no-data">
            {{ $t('table.messages.no_data') }}
          </template>
          <template v-slot:top>
            <v-container class="mt-5">
              <v-row>
                <v-col class="pb-0">
                  <div>
                    <span class="forth--text text-h4 font-weight-bold ">{{
                      $t('messages.search_text')
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex ">
                <v-col cols="10">
                  <div class="align-self-center mr-9">
                    <v-text-field
                      id="search"
                      v-model="search"
                      name="search pa-0 "
                      outlined
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="2"
                  class="mr-auto"
                >
                  <v-btn
                    dense
                    color="forth"
                    class="mb-5 pt-0 pl-10 pr-10 white--text"
                    @click="$router.push({path: '/employee'})"
                  >
                    <span class="font-weight-bold text-h5">
                      {{ $t('messages.Create') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.index`]="props">
            {{ items.indexOf(props.item) + 1 }}
          </template>
          <template v-slot:[`item.name`]="props">
            <a @click="$router.push('/employee-edit/' + props.item.id)">{{
              props.item.name
            }}</a>
          </template>
          <template v-slot:[`item.sex`]="props">
            {{ props.item.sex ? $t('messages.male') : $t('messages.female') }}
          </template>
          <template v-slot:[`item.dob`]="props">
            {{ props.item.dob | formatDate }}
          </template>
          <template v-slot:[`item.position`]="props">
            {{ listPositions.find(x => x.value === props.item.position) ? listPositions.find(x => x.value === props.item.position).text : '' }}
          </template>
          <template v-slot:[`footer.prepend`] />
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'

  export default {
    name: 'EmployeeList',
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        selected: {},
        singleSelect: true,
        options: {},
        accounts: [],
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'start',
            value: 'index',
            class: 'font-weight-bold',
            sortable: false,
            width: '3rem',
          },
          {
            text: this.$t('table.headers.agencyIdEmployee'),
            align: 'start',
            value: 'agencyId',
            class: 'font-weight-bold',
            sortable: false,
            width: '8rem',
          },
          {
            text: this.$t('table.headers.position_fulltime'),
            align: 'start',
            value: 'position',
            class: 'font-weight-bold',
            sortable: false,
            width: '10rem',
          },
          {
            text: this.$t('table.headers.name'),
            align: 'start',
            value: 'name',
            class: 'font-weight-bold',
            sortable: false,
            width: '10rem',
          },
          {
            text: this.$t('table.headers.furiganaName'),
            align: 'start',
            value: 'furiganaName',
            class: 'font-weight-bold',
            sortable: false,
            width: '10rem',
          },
          {
            text: this.$t('table.headers.dob'),
            align: 'start',
            value: 'dob',
            class: 'font-weight-bold',
            sortable: false,
            width: '10rem',
          },
          {
            text: this.$t('table.headers.sex'),
            align: 'start',
            value: 'sex',
            class: 'font-weight-bold',
            sortable: false,
            width: '7rem',
          },
          {
            text: this.$t('table.headers.email'),
            align: 'start',
            value: 'email',
            class: 'font-weight-bold',
            sortable: false,
            width: '10rem',
          },
          {
            text: this.$t('table.headers.contactAddress'),
            align: 'start',
            value: 'contactAddress',
            class: 'font-weight-bold',
            sortable: false,
            width: '15rem',
          },
          {
            text: this.$t('table.headers.contactPhone'),
            align: 'start',
            value: 'contactPhone',
            class: 'font-weight-bold',
            sortable: false,
            width: '10rem',
          },
        ],
        editedIndex: -1,
        items: [],
        currentOptions: {},
        search: '',
        loading: false,
        totalItems: 1,
      }
    },
    computed: {
      ...get('employee', ['list', 'message', 'status', 'error', 'totalCount', 'listPositions']),
    },
    watch: {
      list (value) {
        this.$set(this, 'items', value.data.employees)
        window.scrollTo(0, document.body.scrollHeight)
      },
      status (value) {
        this.loading = value === 'loading'
      },
      error (value) {
        this.showSnackBar(value)
      },
      totalCount (value) {
        this.totalItems = value
      },
    },
    created () {
      this.$store.dispatch('employee/getEmployees')
    },
    mounted () {
      if (this.$route.query) {
        if (this.$route.query.success) {
          this.showSnackBar('messages.success')
        } else if (this.$route.query.deleteSuccess) {
          this.showSnackBar('messages.deleteSuccess')
        } else if (this.$route.query.updateSuccess) {
          this.showSnackBar('messages.updateSuccess')
        }
      }
      // setting table's headers
      this.$store.dispatch('employee/getNewPass', '')
    },
    methods: {
      OnclickCSV () {},

      highlightClickedRow (event) {
        const tr = event.target.parentNode
        tr.classList.add('highlight')
      },

      toEvaluation (item) {
        this.$router.push('/employee-eval/' + item.user_id)
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
    },
  }
</script>
